export const VAT_MULTIPLIER = 0.15;

export const orderAddonType = {
  usersExtraSeats: {
    key: "usersExtraSeats",
    name: "Extra User Seats",
    nameTranslations: {
      en: "Extra User Seats",
      ar: "مقاعد مستخدم إضافية",
    },
  },
  posExtraTerminals: {
    key: "posExtraTerminals",
    name: "Extra POS Terminals",
    nameTranslations: {
      en: "Extra POS Terminals",
      ar: "أجهزة نقاط البيع الإضافية",
    },
  },
} satisfies Record<
  string,
  {
    key: string;
    name: string;
    nameTranslations: { en: string; ar: string };
  }
>;

export type OrderAddonType = keyof typeof orderAddonType;

export const orderProductsAddonType = [
  orderAddonType.usersExtraSeats.key,
  orderAddonType.posExtraTerminals.key,
] as const;
